/*---------------------------------
     Preloader CSS
-----------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 20000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.loader {
  text-align: center;
  margin: 5px;
  border-radius: 50%;
  border: 4px solid #1d0367;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
}

.loader-inner-1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation: change_first_circle 4s ease-in-out infinite;
          animation: change_first_circle 4s ease-in-out infinite;
}

.loader-inner-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation: change_second_circle 4s ease-in-out infinite;
          animation: change_second_circle 4s ease-in-out infinite;
}

.loader-inner-3 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  width: 40px;
  height: 40px;
  -webkit-animation: change_last_circle 4s linear  infinite;
          animation: change_last_circle 4s linear  infinite;
}

@-webkit-keyframes change_first_circle {
  50% {
    -webkit-transform: rotateX(360deg) scale(0.8);
            transform: rotateX(360deg) scale(0.8);
  }
}

@keyframes change_first_circle {
  50% {
    -webkit-transform: rotateX(360deg) scale(0.8);
            transform: rotateX(360deg) scale(0.8);
  }
}

@-webkit-keyframes change_second_circle {
  50% {
    -webkit-transform: rotateY(360deg) scale(0.8);
            transform: rotateY(360deg) scale(0.8);
  }
}

@keyframes change_second_circle {
  50% {
    -webkit-transform: rotateY(360deg) scale(0.8);
            transform: rotateY(360deg) scale(0.8);
  }
}

@-webkit-keyframes change_last_circle {
  50% {
    -webkit-transform: rotateX(360deg) scale(0.8);
            transform: rotateX(360deg) scale(0.8);
  }
}

@keyframes change_last_circle {
  50% {
    -webkit-transform: rotateX(360deg) scale(0.8);
            transform: rotateX(360deg) scale(0.8);
  }
}
/* Pricing */
.pricing {
  padding: 50px;
  background: linear-gradient(-45deg, #1391a5, #274685);
  color: #fff;
  padding-bottom: 4rem !important;
  position: relative;
  border-radius: 4px;
  color: #000000;
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
}

.pricing h3 {
  color: #2d71a1;
}

.pricing .price-cta {
  text-decoration: none;
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  left: 0;
}

.pricing .price-cta .price {
  display: block;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 300;
}

.pricing .popularity {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .2rem;
  display: block;
  margin-bottom: 20px;
}

.pricing ul {
  margin-bottom: 50px;
}

.pricing ul li {
  margin-bottom: 10px;
}

.pricing .btn-white {
  background: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing .btn-white:hover {
  color: #2d71a1;
}

.pricing.popular {
  background: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  color: #000000;
  background: linear-gradient(-45deg, #1391a5, #274685);
  color: #fff;
  box-shadow: none;
}

.pricing.popular .popularity {
  color: #b3b3b3;
}

.pricing.popular h3 {
  color: #fff;
  background: none;
}

.pricing.popular .btn-white {
  border: 2px solid #2d71a1;
}